import { environmentURLs } from '../../common/environment/CaptureEnvironment';

export default {
  footer: {
    social: [
      {
        code: 'facebook',
        url: 'https://www.facebook.com/groups/551215795213352/?source_id=180950569178220' // prettier-ignore
      },
      {
        code: 'twitter',
        url: 'https://twitter.com/fortellisdev'
      },
      {
        code: 'github',
        url: 'https://github.com/fortellis'
      },
      {
        code: 'youtube',
        url: 'https://www.youtube.com/channel/UCk4etySO7-MJhYCPNDtox2A'
      }
    ],
    nav: [
      {
        text: 'About',
        menu: [
          {
            text: 'Community',
            url: environmentURLs.footer.support_links.developerCommunity
          },
          {
            text: 'Marketplace',
            url: environmentURLs.marketplaceURL
          }
        ]
      },
      {
        text: 'Documentation',
        menu: [
          {
            text: 'API Directory',
            url: environmentURLs.api_reference_URL
          },
          {
            text: 'Resources',
            url: environmentURLs.docs
          }
        ]
      },
      {
        text: 'Support',
        menu: [
          {
            text: 'Contact Us',
            url: environmentURLs.footer.support_links.contactUs
          },
          {
            text: 'Developer Forum',
            url: environmentURLs.community_blog_URL
          },
          {
            text: 'Professional Services',
            url: environmentURLs.professional_services_URL
          }
        ]
      }
    ],
    copyright: {
      trademark: `©${new Date().getFullYear()}. Fortellis is a registered trademark of CDK Global, LLC.`,
      legal: {
        text: 'Privacy & Legal',
        url: environmentURLs.footer.legal_links.legal
      },
      terms: {
        text: 'Terms of Use',
        url: environmentURLs.footer.legal_links.termsOfUse
      }
    }
  }
};
