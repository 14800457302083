import React from 'react';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import SolutionTypeURLMappingUtil from '../SolutionTypeURLMapperUtil';
import FortellisConstants from '../FortellisConstants';
import SolutionStatus from '../../common/SolutionStatus';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import { withEntityContext } from '@cdk-prod/fortellis-entity-context';

const solutionQuery = gql`
  query($orgId: String!, $id: ID!, $type: String!, $listingVersion: String) {
    apps(orgId: $orgId, id: $id, type: $type, listingVersion: $listingVersion) {
      id
      name
    }
    solutionList(id: $id) {
      status
      listingVersion
      overview {
        name
      }
    }
  }
`;

class SolutionItemBreadcrumb extends React.Component {
  state = {
    data: FortellisConstants.DEFAULT_SOLUTION_NAME
  };

  getSolutionData = async (entityId, id, type, listingVersion) => {
    const client = getApolloClient();
    let data = await client.query({
      query: solutionQuery,
      variables: {
        orgId: entityId,
        id: id,
        type: type,
        listingVersion: listingVersion
      }
    });
    return data;
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.auth.isAuthenticated &&
      prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    ) {
      const { entities, entitiesIndex, entitiesLoaded } = this.props;
      const entityId = entitiesLoaded
        ? entities[entitiesIndex]
        : FortellisConstants.DEFAULT_ORG_ID;
      let data = this.getSolutionData(
        entityId,
        this.props.match.params.id,
        SolutionTypeURLMappingUtil.getSolutionTypeName(
          this.props.match.params.type
        ),
        this.props.match.params.listingVersion
      );
      data.then(response => {
        let data = '';
        let maxListingVersion_index = 0;
        if (response && response.data) {
          if (response.data.solutionList && response.data.solutionList[0]) {
            let maxListingVersion =
              response.data.solutionList[0].listingVersion;
            response.data.solutionList.forEach(solution => {
              if (solution.status === SolutionStatus.PUBLISHED) {
                data = solution.overview.name;
              } else {
                if (parseInt(maxListingVersion,10) < parseInt(solution.listingVersion,10)) {
                  maxListingVersion = solution.listingVersion;
                  maxListingVersion_index = response.data.solutionList.indexOf(
                    solution
                  );
                }
              }
            });
            if (data === '') {
              data =
                response.data.solutionList[maxListingVersion_index].overview
                  .name;
            }
          } else {
            if (response.data.apps && response.data.apps[0]) {
              data = response.data.apps[0].name;
            }
          }
        }
        this.setState({ data: data });
      });
    }
  }
  render() {
    return <span>{this.state.data}</span>;
  }
}

export default withAuth(withEntityContext(SolutionItemBreadcrumb));
