import React, { useState, useEffect } from 'react';
import {
  SimpleTable,
  SimpleTableContainer,
  SimpleTableHead,
  SimpleTableHeader,
  SimpleTableRow,
  SimpleTableData,
  SimpleTableWrapper
} from 'cdk-radial';
import styled from 'styled-components';
import { injectIntl } from 'react-intl';
import gql from 'graphql-tag';
import { withApollo } from 'react-apollo';
import { FormattedMessage } from 'react-intl';
import FortellisConstants from '../common/FortellisConstants';
import { withAuth } from '@cdk-prod/fortellis-auth-context';
import SolutionListingAPI from '../../api/StoreListingAPI';
import { environmentURLs } from '../common/environment/CaptureEnvironment';
import replaceAll from 'string.prototype.replaceall';
import { sendAmplitudeData } from '../../utils/amplitude';
import {
  AMPLITUDE_EVENTS,
  AMPLITUDE_PROPERTIES
} from '../../utils/amplitude-constants';
import Spinner from '../common/Spinner';

const ApiInfoSimpleTableRow = styled(SimpleTableRow)`
  :nth-child(even) {
    background-color: #151b250d;
    border-top: 1px solid #dcddde;
  }
  &:last-child {
    border-bottom: none;
  }
`;

const getAsyncApiData = gql`
  query($id: ID) {
    asyncApiDetails(id: $id) {
      id
      orgId
      name
      status
      website
      created
      apigeeAppId
      displayName
      description
      oneToOneUI
      apiProducts {
        name
        displayName
      }
      integrations {
        id
        name
        displayName
        description
        apiType
        providers {
          id
          apiProviderName
          entityId
        }
      }
    }
  }
`;

const removeApiV2Prefix = str => {
  const prefixToRemove = 'api-v2-';
  return str.startsWith(prefixToRemove)
    ? str.substring(prefixToRemove.length)
    : str;
};

const APIDescriptionTextLimit = 150;
const ReadMore = ({ textLimit, text }) => {
  const [showMore, setShowMore] = useState(false);
  const [desc, setDesc] = useState(text);

  useEffect(() => {
    if (text.length > textLimit) {
      if (showMore) {
        setDesc(text);
      } else {
        setDesc(text.substring(0, textLimit).trim() + '...');
      }
    }
  }, [showMore]);

  function suppressMarkdown(desc = '') {
    return replaceAll(desc, '#', '');
  }
  const apiDescription = () => {
    return suppressMarkdown(desc);
  };

  return (
    <>
      <div className="api_description">{apiDescription()}</div>
    </>
  );
};
class ApiInfo extends React.Component {
  state = {
    apiProviderMapping: null,
    loading: false,
    isConsentLoaded: false,
    providersData: []
  };

  getApis(solution) {
    if (this.isFortellisSolution()) {
      return solution.registration_details &&
        solution.registration_details.api_details
        ? solution.registration_details.api_details.sort((a, b) => {
            var x =
              a && a.displayName ? a.displayName.toLowerCase() : undefined;
            var y =
              b && b.displayName ? b.displayName.toLowerCase() : undefined;
            if (x > y) return 1;
            if (x < y) return -1;
            return 0;
          })
        : [];
    }
    if (this.isPartnerProgram()) {
      return solution.partner_program_registration_details &&
        solution.partner_program_registration_details.partner_api_details
        ? solution.partner_program_registration_details.partner_api_details.sort(
            (a, b) => {
              var x =
                a && a.applicationName
                  ? a.applicationName.toLowerCase()
                  : undefined;
              var y =
                b && b.applicationName
                  ? b.applicationName.toLowerCase()
                  : undefined;
              if (x > y) return 1;
              if (x < y) return -1;
              return 0;
            }
          )
        : [];
    }
    return [];
  }

  getSolutionType(solution) {
    return solution.solutionType;
  }

  isPartnerProgram() {
    return (
      this.props.solution.solutionType === FortellisConstants.PARTNER_PROGRAM
    );
  }

  isFortellisSolution() {
    return this.props.solution.solutionType === FortellisConstants.FORTELLIS;
  }

  createAndSubmitAmplitudeData = api => {
    const { solution, entity } = this.props;
    let amplitudeData = {
      [AMPLITUDE_PROPERTIES.APP_ID]: solution?.id,
      [AMPLITUDE_PROPERTIES.APP_NAME]: solution?.overview?.name,
      [AMPLITUDE_PROPERTIES.ORG_ID]: entity?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.ORG_NAME]: entity?.name || 'N/A',
      [AMPLITUDE_PROPERTIES.API_ID]: api?.id || 'N/A',
      [AMPLITUDE_PROPERTIES.API_NAME]: api?.displayName || 'N/A'
    };
    sendAmplitudeData(AMPLITUDE_EVENTS.VIEW_API, amplitudeData);
  };

  async getAsyncApiDetails(id) {
    let response = await this.props.client.query({
      query: getAsyncApiData,
      variables: {
        id: id
      }
    });
    return response.data;
  }

  transformData(inputData) {
    const result = { providers: [] };
    // Iterate over the input data and transform it
    inputData.forEach(item => {
      if (item.providers && item.providers.length > 0) {
        item.providers.forEach(provider => {
          // Create a provider object to match the required output format
          const providerData = {
            description: item.description,
            displayName: item.displayName,
            id: item.id,
            name: provider.apiProviderName,
            apiType: item.apiType,
            api: item.id,
            providers: [
              {
                name: provider.apiProviderName,
                id: provider.id
              }
            ]
          };
          // Push the providerData to the result providers array
          result.providers.push(providerData);
        });
      }
    });

    return result;
  }

  matchDataDynamic(listingApis, allProvider) {
    return listingApis
      .map(item1 => {
        // Strip the "api-v2-" prefix from the id in item1, if present
        const id1 = item1.id.startsWith('api-v2-')
          ? item1.id.substring(7)
          : item1.id;
        // Find matching item from data2, after stripping the "api-v2-" prefix if present in data2
        const matchedItem = allProvider.find(item2 => {
          const id2 = item2.id.startsWith('api-v2-')
            ? item2.id.substring(7)
            : item2.id;
          return id1 === id2;
        });
        // Return the matched item or null if no match is found
        return matchedItem || null;
      })
      .filter(item => item !== null); // Remove null values (items without a match)
  }

  async fetchProviders(apis) {
    const solutionId = this.props.solution.id;
    const auth = this.props.auth;
    let loggedInUserApiData = [];
    let apiDetails = [];
    if (
      this.props.solution &&
      this.props.solution.id &&
      auth?.isAuthenticated
    ) {
      apiDetails = await this.getAsyncApiDetails(solutionId);
      // eslint-disable-next-line no-unused-expressions
      apiDetails.asyncApiDetails.integrations?.forEach(item => {
        let apiObj = {};
        if (item.apiType === 'api') {
          apiObj.api = item.name;
          apiObj.description = item.description;
          apiObj.id = item.id;
          apiObj.apiType = item.apiType;
          apiObj.name = item.displayName;
          apiObj.productName = item.displayName;
          apiObj.providers = item.providers.map(p => {
            return { name: p.apiProviderName };
          });
        } else {
          // asycn api
          apiObj.api = item.name;
          apiObj.description = item.description;
          apiObj.id = item.id;
          apiObj.name = item.displayName;
          apiObj.apiType = item.apiType;
          apiObj.api = item.id;
          apiObj.providers = item.providers.map(p => {
            return { name: p.apiProviderName };
          });

          if (apiObj && Object.keys(apiObj).length > 0)
            loggedInUserApiData.push(apiObj);
        }

        let providerData = this.transformData(
          apiDetails.asyncApiDetails.integrations
        );

        // need to match only listing apis
        let filteredProviderData = this.matchDataDynamic(
          this.props?.solution?.registration_details?.api_details,
          providerData.providers
        );
        this.setState({ providersData: { providers: filteredProviderData } });
      });
    } else if (
      this.props.solution &&
      this.props.solution.id &&
      !this.props.auth.isAuthenticated
    ) {
      let response = await SolutionListingAPI.anonymous.getApiDetailsForSolution(
        { solutionId: solutionId }
      );
      // need to match only listing apis
      let filteredProviderData = this.matchDataDynamic(
        this.props?.solution?.registration_details?.api_details,
        response.data.data.providers
      );
      this.setState({ providersData: { providers: filteredProviderData } });
    }
  }

  componentDidMount() {
    if (!this.props.solution || !this.props.solution.overview) {
      return null;
    }
    this.fetchProviders(this.getApis(this.props.solution));
  }

  render() {
    const { solution } = this.props;
    if (!solution || !solution.overview) {
      return null;
    }
    return (
      <div>
        <div>
          <div className="apiInfo-subtitle">
            {this.isFortellisSolution() ? (
              <FormattedMessage
                id="ApiInfo.helpText"
                defaultMessage={`This app uses the following APIs published on the Fortellis platform. Each API can have one or more providers. Please check if your preferred app provider is in the list because the selection of the API provider will be required while configuring the app subscription.`}
              />
            ) : (
              <FormattedMessage
                id="ApiInfo.apiUsedByIntegrationMessage"
                defaultMessage={`This app uses external APIs which are not integrated through the Fortellis platform. This app integrates with the following applications.`}
              />
            )}
          </div>
          <div className="api-info-table">
            <SimpleTableWrapper data-testid="apiInfo-simple-table-wrapper">
              <SimpleTableContainer>
                <SimpleTable aria-label="ApiInfo-Table">
                  <SimpleTableHead>
                    <SimpleTableRow>
                      <SimpleTableHeader
                        className={
                          this.isFortellisSolution()
                            ? 'api-info-table-cell-25'
                            : 'api-info-table-cell-50'
                        }
                        colSpan="1"
                      >
                        {this.isFortellisSolution() ? (
                          <FormattedMessage
                            id="ApiInfo.apiName"
                            defaultMessage="Fortellis APIs"
                          />
                        ) : (
                          <FormattedMessage
                            id="ApiInfo.applicationiName"
                            defaultMessage="Application Name"
                          />
                        )}
                      </SimpleTableHeader>
                      <SimpleTableHeader
                        className="api-info-table-cell-25"
                        data-cy="th_api_info_description"
                        colSpan="1"
                      >
                        <FormattedMessage
                          id="ApiInfo.description"
                          defaultMessage="Description"
                        />
                      </SimpleTableHeader>
                      <SimpleTableHeader
                        className="api-info-table-cell-25"
                        colSpan="1"
                        data-cy="th_api_info_provider"
                      >
                        <FormattedMessage
                          id="ApiInfo.providers"
                          defaultMessage="Providers"
                        />
                      </SimpleTableHeader>
                    </SimpleTableRow>
                  </SimpleTableHead>
                </SimpleTable>
              </SimpleTableContainer>
            </SimpleTableWrapper>
          </div>
          {this.state?.providersData?.providers &&
            this.getApis(solution).map((api, index) => {
              if (
                this.isFortellisSolution() &&
                !this.state?.providersData?.providers?.find(
                  provider =>
                    removeApiV2Prefix(provider.id) === removeApiV2Prefix(api.id)
                )
              ) {
                return null;
              }
              return (
                <ApiInfoSimpleTableRow
                  data-testid="apiInfo-simple-table-row"
                  key={index}
                >
                  {/* api name with link */}
                  <SimpleTableData
                    className={
                      this.isFortellisSolution()
                        ? 'api-info-table-cell-25'
                        : 'api-info-table-cell-50'
                    }
                  >
                    <div className="apiInfo-table-body-api-info">
                      {this.isFortellisSolution() &&
                      this.state?.providersData?.providers
                        ? this.state.providersData.providers.map(provider => {
                            if (
                              removeApiV2Prefix(provider.id) ===
                              removeApiV2Prefix(api.id)
                            ) {
                              return (
                                <div>
                                  <p>
                                    <a
                                      href={`${
                                        environmentURLs.api_reference_URL
                                      }${
                                        provider.apiType === 'api'
                                          ? 'apis'
                                          : 'async-apis'
                                      }/${removeApiV2Prefix(api.id)}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={() =>
                                        this.createAndSubmitAmplitudeData(api)
                                      }
                                    >
                                      {' '}
                                      {provider.displayName ||
                                        api.displayName}{' '}
                                      <i className="material-icons external-link-icon">
                                        open_in_new
                                      </i>{' '}
                                    </a>

                                    <br />
                                  </p>
                                </div>
                              );
                            }
                          })
                        : api.applicationName}
                    </div>
                  </SimpleTableData>
                  {/* api description */}
                  <SimpleTableData className="api-info-table-cell-25">
                    <div className="apiInfo-table-body-api-info">
                      <ReadMore
                        textLimit={APIDescriptionTextLimit}
                        text={(api && api.description) || ''}
                      />
                    </div>
                  </SimpleTableData>

                  {/* provider name */}
                  <SimpleTableData
                    className={
                      this.isFortellisSolution()
                        ? 'api-info-table-cell-25'
                        : 'api-info-table-cell-50'
                    }
                  >
                    {this.isFortellisSolution() ? (
                      <div className="apiInfo-table-body-api-info">
                        {this.state.providersData.providers.map(provider => {
                          if (
                            removeApiV2Prefix(api.id) ===
                            removeApiV2Prefix(provider.providers[0].id)
                          )
                            return (
                              <div className="api-info-table-body-providers-item">
                                {`${provider.providers[0].name}`}
                              </div>
                            );
                        })}
                      </div>
                    ) : null}
                  </SimpleTableData>
                </ApiInfoSimpleTableRow>
              );
            })}
        </div>
        {/* just in case we are still loading the required data */}
        {!this.state?.providersData?.providers && <Spinner />}
      </div>
    );
  }
}

export default injectIntl(withApollo(withAuth(ApiInfo)));
