import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Table,
  CELL_RENDERERS,
  TextButton,
  BUTTON_SIZES,
  TABLE_LAYOUT_OPTIONS
} from 'cdk-radial';
import './styles/subscriptionsData.scss';
import SubscriptionDetailsDialog from './SubscriptionDetailsDialog';
import { PrivacyProtectionOnAppIndicator } from './PrivacyProtectionIndicator';
import { modifyStr } from '../../../utils/utils';
import { environmentURLs } from '../../common/environment/CaptureEnvironment';
import { SEARCH_TYPE } from './constants';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService';

const APP_PUBLISHED_STATUS = 'Published';

const CustomCellRenderer = ({ data, token }) => {
  const { searchType, subData } = data;
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <div>
      <TextButton
        onClick={() => setOpenDialog(true)}
        size={BUTTON_SIZES.SMALL}
        text="Details"
      />
      {openDialog && (
        <SubscriptionDetailsDialog
          setOpenDialog={setOpenDialog}
          data={subData}
          searchType={searchType}
          isOpen={openDialog}
          token={token}
        />
      )}
    </div>
  );
};

const marketplaceAppUIRenderer = gridParams => {
  const { data } = gridParams;

  return (
    <div className="subscription_table-app-name-container">
      {data?.subData?.solution?.status === APP_PUBLISHED_STATUS ? (
        <TextButton
          id="app-name"
          text={data.appName}
          href={`${environmentURLs.marketplaceURL}solutions/${modifyStr(
            data.subData.solution.orgName
              .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
              .toLowerCase()
          ).toLowerCase()}/${modifyStr(
            data.appName
              .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
              .toLowerCase()
          ).toLowerCase()}?id=${data.appId}`}
          style={{ marginLeft: '0px' }}
          target="_blank"
        />
      ) : (
        <span className="subscription_table-unpublished-app-name">
          {data.appName}
        </span>
      )}
      {data.privacyProtection && (
        <span className="subscription_app-privacy-protection">
          <PrivacyProtectionOnAppIndicator />
        </span>
      )}
    </div>
  );
};

const dateComparator = (date1, date2) => {
  let d1 = moment(date1, 'DD/MM/YYYY, hh:mm a');
  let d2 = moment(date2, 'DD/MM/YYYY, hh:mm a');

  return d1 === d2 ? 0 : d1 > d2 ? 1 : -1;
};

const appHasPrivacyProtection = solution => {
  return solution?.registration_details?.api_details?.some(
    api => api.privacyProtection
  );
};

function SubscriptionDetailsTable(props) {
  const { searchType, searchName, tableData, token } = props;
  // pass token to  CustomCellRenderer

  const [columnData, setColumnData] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [gridApi, setGridApi] = useState(null);

  useEffect(() => {
    let colData = [];
    let row = null;
    if (tableData && tableData.length) {
      tableData.map(item => {
        let appName = '';
        let apiIntegrationData = '';
        if (item.solution) {
          if (item.solution.overview && item.solution.overview.name) {
            appName = item.solution.overview.name;
          } else {
            appName = item.solution.name;
          }

          if (item.solution.registration_details) {
            apiIntegrationData = item.solution.registration_details.api_details;
          } else if (item.solution.apiProducts) {
            apiIntegrationData = item.solution.apiProducts;
          }
        }
        row = {
          entityId: item.subscriptionData.orgId || '',
          subscriptionId: item.subscriptionData.subscriptionId || '',
          storeId: item.subscriptionData.storeId || 'NA',
          rowHeight: 52,
          subscribedOn: moment(item.subscriptionData.created)
            .utc()
            .format('DD/MM/YYYY, hh:mm a'),
          entityName: item.subscriptionData.orgName || '',
          orgAddress: item.subscriptionData.orgAddress || 'NA',
          appName,
          appId:
            (item.solution && item.solution.id) ||
            item.subscriptionData.solutionId ||
            '',
          subData: item,
          searchType,
          status: item.subscriptionData.status || '',
          appProvider: (item.solution && item.solution.orgName) || '',
          cmf: (item.metadata && item.metadata.selectedCmfNumber) || 'NA',
          cNumber: (item.metadata && item.metadata.cNumber) || 'NA',
          ipAddress: (item.metadata && item.metadata.ipAddress) || 'NA',
          apis: getAPINames(apiIntegrationData),
          privacyProtection: appHasPrivacyProtection(item.solution),
          activateOnly:
            item.solution && item.solution.activateOnly !== false
              ? 'YES'
              : 'NO',
          selectedDMS: getDealerSelectedDMSAccountsByAPI(item)
        };
        colData.push(row);
      });
      setColumnData(colData);
    }
  }, [tableData, props]);

  const handleSearchInputChange = newValue => {
    setSearchInputValue(newValue);
  };

  const getAPINames = data => {
    let apis = [];
    if (data && data.length) {
      apis = data.map(item => {
        return item.displayName ? item.displayName : item.id;
      });
    }
    return apis.length && apis.join(', ');
  };

  const getDealerSelectedDMSAccountsByAPI = data => {
    let { metadata, solution } = data;
    const apiNamesById = {};
    solution &&
      solution.registration_details &&
      solution.registration_details.api_details &&
      solution.registration_details.api_details.length &&
      solution.registration_details.api_details.forEach(apiDetail => {
        if (apiDetail && apiDetail.id) {
          apiNamesById[apiDetail.id] =
            apiDetail.displayName || apiDetail.name || apiDetail.id;
        }
      });
    let selectedDmsByApi = {};
    metadata &&
      metadata.orgIdList &&
      metadata.orgIdList.length &&
      metadata.orgIdList.forEach(item => {
        if (item && item.dmsAccount) {
          if (typeof item.api === 'string' && item.api) {
            if (item.api in selectedDmsByApi)
              selectedDmsByApi[item.api] =
                selectedDmsByApi[item.api] + ', ' + item.dmsAccount;
            else selectedDmsByApi[item.api] = item.dmsAccount;
          } else if (item.api && Array.isArray(item.api) && item.api.length) {
            item.api.forEach(API => {
              if (API in selectedDmsByApi)
                selectedDmsByApi[API] =
                  selectedDmsByApi[API] + ', ' + item.dmsAccount;
              else selectedDmsByApi[API] = item.dmsAccount;
            });
          }
        }
      });
    if (Object.keys(selectedDmsByApi).length)
      return Object.keys(selectedDmsByApi).map(apiId => {
        const matchApiId = apiId.match(/(?:api(?:-v\d+)?-)?(.+)/);
        return `${
          apiNamesById[matchApiId && matchApiId[1] ? matchApiId[1] : apiId]
        } => ${selectedDmsByApi[apiId]} \n`;
      });
    else return 'NA';
  };

  const handleGridReady = gridParams => {
    setGridApi(gridParams.api);
  };

  const handleDownloadClick = () => {
    const params = {
      fileName: `${searchName.name}.csv`,
      skipHeader: false,
      allColumns: false,
      columnKeys: [
        'entityName',
        'entityId',
        'orgAddress',
        'appName',
        'appId',
        'appProvider',
        'subscriptionId',
        'storeId',
        'subscribedOn',
        'status',
        'cmf',
        'cNumber',
        'ipAddress',
        'apis',
        'activateOnly',
        'selectedDMS'
      ]
    };
    gridApi.exportDataAsCsv(params);
  };

  const columns = [
    {
      autoHeight: true,
      cellRendererFramework: params => (
        <CustomCellRenderer {...params} token={token} />
      ),
      field: 'viewButton',
      headerName: '',
      minWidth: 100,
      width: 100,
      wrapText: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'entityName',
      headerName: 'Organization Name',
      minWidth: 360,
      width: 360,
      hide: searchType === SEARCH_TYPE.ORG
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'entityId',
      headerName: 'Organization Id',
      minWidth: 300,
      width: 300,
      hide: searchType === SEARCH_TYPE.ORG
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'orgAddress',
      headerName: 'Organization Address',
      minWidth: 300,
      width: 300,
      hide: true
    },
    {
      cellRendererFramework: marketplaceAppUIRenderer,
      field: 'appName',
      headerName: 'App Name',
      minWidth: 360,
      width: 360,
      hide: searchType === SEARCH_TYPE.APP
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'appId',
      headerName: 'App Id',
      minWidth: 300,
      width: 300,
      hide: searchType === SEARCH_TYPE.APP
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'activateOnly',
      headerName: 'Enable Only',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'subscriptionId',
      headerName: 'SubscriptionId',
      minWidth: 300,
      width: 300
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'storeId',
      headerName: 'StoreId',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      width: 120
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'subscribedOn',
      headerName: 'Subscribed On',
      minWidth: 180,
      width: 180,
      sortable: true,
      unSortIcon: true,
      comparator: dateComparator
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'appProvider',
      headerName: 'App Provider',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'cmf',
      headerName: 'CMF',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'cNumber',
      headerName: 'CNumber',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'ipAddress',
      headerName: 'IPAddress',
      hide: true
    },

    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'apis',
      headerName: 'APIs',
      hide: true
    },
    {
      cellRenderer: CELL_RENDERERS.TEXT,
      field: 'selectedDMS',
      headerName: 'selected DMS',
      hide: true
    }
  ];

  const getRowHeight = params => params.data.rowHeight;

  const getTableTitle = (searchName = {}) => {
    return (
      <div className="subscription_table-title">
        {searchType === SEARCH_TYPE.APP ? (
          tableData[0]?.solution.status === APP_PUBLISHED_STATUS ? (
            <TextButton
              id="app-name"
              text={searchName && searchName.name}
              href={`${environmentURLs.marketplaceURL}solutions/${modifyStr(
                tableData[0]?.solution.orgName
                  .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
                  .toLowerCase()
              ).toLowerCase()}/${modifyStr(
                searchName.name
                  .replace(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/g, '-')
                  .toLowerCase()
              ).toLowerCase()}?id=${searchName.id}`}
              target="_blank"
            />
          ) : (
            searchName && searchName.name
          )
        ) : searchType === SEARCH_TYPE.CMF || searchType === SEARCH_TYPE.SUB ? (
          searchName.id
        ) : (
          searchName.name
        )}
        {searchType === SEARCH_TYPE.APP &&
          appHasPrivacyProtection(tableData[0]?.solution) && (
            <span className="subscription_app-privacy-protection">
              <PrivacyProtectionOnAppIndicator />
            </span>
          )}
      </div>
    );
  };

  return (
    <div>
      <div className="table">
        <Table
          columns={columns}
          data={columnData}
          loadingMessage="Fetching Subscriptions"
          getRowHeight={getRowHeight}
          isSearchable={true}
          onSearchInputChange={handleSearchInputChange}
          searchInputValue={searchInputValue}
          isDownloadable={true}
          isPrintable={false}
          resizableHeaders={false}
          showNoRowsOverlay={false}
          showFooter={true}
          hideTableTitle={false}
          headerClassName="table_header"
          headerTitle={getTableTitle(searchName)}
          onGridReady={handleGridReady}
          customDownloadHandler={() => handleDownloadClick(columnData)}
          rowDataChangeDetectionStrategy={
            ChangeDetectionStrategyType.IdentityCheck
          }
          tableLayout={TABLE_LAYOUT_OPTIONS.AUTO_HEIGHT}
          id="table-with-auto-height-1"
          noRowsFoundMessage="No subscriptions found"
        />
      </div>
    </div>
  );
}

export {
  CustomCellRenderer,
  marketplaceAppUIRenderer,
  SubscriptionDetailsTable,
  dateComparator
};
