import SolutionStatus from '../../components/common/SolutionStatus';
import moment from 'moment';
import { FormConfig } from '../../components/solution-submission/solution_submition_form_config';
import { FormUtils } from '../../components/solution-submission/common-form-api/utils/FormUtils';
import * as SolutionFormData from '../../components/solution-submission/solution_data_object';
import { FormValidationUtils } from '../../components/solution-submission/common-form-api/utils/FormValidationUtils';
import gql from 'graphql-tag';
import { getApolloClient } from '../GraphQLClient';
import { environmentURLs } from '../../components/common/environment/CaptureEnvironment';
import UploadDocUtils from '../../components/solution-submission/common-form-api/utils/UploadDocUtils';

class SolutionOperations {
  uploadDocUtils = new UploadDocUtils();

  getUserName = userNameData => {
    let firstName =
      userNameData && userNameData.length > 0
        ? userNameData[0].charAt(0).toUpperCase() + userNameData[0].slice(1)
        : ``;
    let lastName =
      userNameData && userNameData.length > 1
        ? userNameData[1].charAt(0).toUpperCase() + userNameData[1].slice(1)
        : ``;
    return { firstName: firstName, lastName: lastName };
  };

  getAttachment = (name, agreementUrl) => {
    return {
      mimeType: 'application/pdf',
      fileName: `${name}.pdf`,
      disposition: 'attachment',
      cid: 'pdf',
      fileUrl: agreementUrl
    };
  }

  onSave = async options => {
    const client = getApolloClient();
    let {
      formValueFinal,
      formStaticValuesTemp,
      solutionWillSave,
      solutionDidSave,
      solutionFailedToSave,
      solution
    } = options;
    let status = SolutionStatus.DRAFT;

    if (formValueFinal.status === SolutionStatus.REGISTERED) {
      formValueFinal.status = SolutionStatus.DRAFT;
    }
    if (
      solution &&
      solution.status &&
      solution.status === formValueFinal.status
    ) {
      let solutionStatusInDb = JSON.parse(JSON.stringify(solution.status));
      status = solutionStatusInDb;
    } else {
      status = formValueFinal.status ? formValueFinal.status : status;
    }

    if (solutionWillSave && solutionWillSave instanceof Function) {
      solutionWillSave();
    }

    try {
      //step 3
      let { formValueFinal, staticData } = await this._mapStaticData(
        options,
        status,
        null
      );

      const formValues = await this._mapFormValueForSubmit(
        formValueFinal,
        staticData
      );

      let { formStaticValues } = options;

      let savedSolutionResponse = await this._saveFormData(formValues);
      formValueFinal.apigee_id =
        savedSolutionResponse.data.submitSolution.apigee_id;
      formValueFinal.id = savedSolutionResponse.data.submitSolution.id;

      formValueFinal['status'] = status;

      if (solutionDidSave && solutionDidSave instanceof Function) {
        solutionDidSave(formValueFinal, formStaticValuesTemp);
      }
    } catch (err) {
      console.log(err);
      solutionFailedToSave(formValueFinal, formStaticValuesTemp);
    }
  };

  onPublish = async options => {
    const {
      formValueFinal,
      solutionWillPublish,
      solutionWasPublished
    } = options;

    let status = SolutionStatus.PUBLISHED;

    if (solutionWillPublish && solutionWillPublish instanceof Function) {
      solutionWillPublish();
    }

    let submissionNote = await this._fetchSolutionNote(formValueFinal);
    let commentText =
      submissionNote === ''
        ? `Published by user ${formValueFinal.developerName}`
        : submissionNote;

    try {
      await this._changeStatus(
        options,
        status,
        commentText,
        `Your Fortellis App Has Been Published`,
        `solutionPublishedEmail`,
        formValueFinal.solutionType
      );

      if (solutionWasPublished && solutionWasPublished instanceof Function) {
        solutionWasPublished(formValueFinal);
      }

      try {
        await this._sendSubmissionEmailWithSignedDocument(
          options,
          'Internal Alert - App Publisher Signed Fortellis Agreement',
          'solutionSubmissionAppContract'
        );
      } catch (e) {
        console.error(e);
      }
    } catch (err) {
      throw err;
    }
  };

  onWithdraw = async options => {
    const {
      formValueFinal,
      solutionWillWithdraw,
      solutionWasWithdrawn
    } = options;

    if (solutionWillWithdraw && solutionWillWithdraw instanceof Function) {
      solutionWillWithdraw();
    }

    let status = SolutionStatus.WITHDRAWN;
    let commentText = `Withdrawn by user ${formValueFinal.developerName}`;
    await this._changeStatus(
      options,
      status,
      commentText,
      `Your Fortellis App Has Been Withdrawn`,
      `solutionWithdrawnEmail`,
      formValueFinal.solutionType
    );

    if (solutionWasWithdrawn && solutionWasWithdrawn instanceof Function) {
      solutionWasWithdrawn(formValueFinal);
    }
  };

  onRecall = async options => {
    const {
      formValueFinal,
      formStaticValues,
      solutionWillBeRecalled,
      solutionWasRecalled
    } = options;
    let status = SolutionStatus.RECALLED;

    if (solutionWillBeRecalled && solutionWillBeRecalled instanceof Function) {
      solutionWillBeRecalled();
    }

    let submissionNote = await this._fetchSolutionNote(formValueFinal);
    let commentText =
      submissionNote === ''
        ? `Recalled by user ${formValueFinal.developerName}`
        : submissionNote;

    try {
      await this._changeStatus(
        options,
        status,
        commentText,
        `Your App has been recalled`,
        `recalledSolutionEmail`,
        formValueFinal.solutionType
      );
      if (solutionWasRecalled && solutionWasRecalled instanceof Function) {
        solutionWasRecalled(formValueFinal, formStaticValues, commentText);
      }
    } catch (err) {
      if (solutionWasRecalled && solutionWasRecalled instanceof Function) {
        solutionWasRecalled(formValueFinal, formStaticValues, commentText);
      }
    }
  };

  onSubmit = async options => {
    const client = getApolloClient();
    let {
      formStaticValuesTemp,
      menu,
      solution,
      solutionWillSubmit,
      solutionValuesWillMap,
      solutionWillSave,
      solutionDidSave
    } = options;

    if (options.uploadTerms) {
      try {
        const termsAndConditionsUrl = await this.uploadSolutionTerms(
          options,
          options.formValueFinal
        );
        options.formValueFinal.overview.overview.termsAndConditionsUrl = termsAndConditionsUrl;
        options.termsUploaded();
      } catch (e) {
        console.error('failed to upload terms', e);
      }
    }

    let status = SolutionStatus.SUBMITTED;

    let solutionStatusInDb = '';
    if (solution && solution.status) {
      solutionStatusInDb = JSON.parse(JSON.stringify(solution.status));
    }

    if (options) {
      //step 1
      if (solutionWillSubmit && solutionWillSubmit instanceof Function) {
        options.solutionWillSubmit();
      }

      //step 2
      const { comment, commentText } = this._getSubmissionComment(
        options,
        status
      );

      //step 3
      const { formValueFinal, staticData } = await this._mapStaticData(
        options,
        status,
        comment
      );
      if (
        staticData &&
        solutionValuesWillMap &&
        solutionValuesWillMap instanceof Function
      ) {
        solutionValuesWillMap(staticData);
      }
      const formValues = await this._mapFormValueForSubmit(
        formValueFinal,
        staticData
      );

      //step 4
      const response = await this._validateForm(formValues, menu);

      if (response && response.isValid) {
        let { formStaticValues } = options;
        //step 5
        if (solutionWillSave && solutionWillSave instanceof Function) {
          solutionWillSave(response, menu);
        }
        formValues.worksWithIds = formValueFinal.worksWithIds;
        formValues.oneToOneUI = options.oneToOneUI;

        //step 6
        let savedSolutionResponse = await this._saveFormData(formValues);
        formValueFinal.apigee_id =
          savedSolutionResponse.data.submitSolution.apigee_id;
        formValueFinal.id = savedSolutionResponse.data.submitSolution.id;

        await this._sendEmailForStatus(
          options,
          `Your Fortellis App Has Been Submitted`,
          solutionStatusInDb === SolutionStatus.REJECTED
            ? `solutionResubmittedEmail`
            : `solutionPendingReviewEmail`,
          formValues.overview.name,
          status,
          formValues.submittedOn
        );

        formValueFinal['status'] = status;
        formStaticValues = JSON.parse(JSON.stringify(formStaticValuesTemp));

        if (solutionDidSave && solutionDidSave instanceof Function) {
          solutionDidSave(formValueFinal, formStaticValues, commentText);
        }
      }
    }
  };

  uploadSolutionTerms = async (
    { termsFile, termsFileName, signatureCoordinates, termsType },
    { id, orgId, listingVersion }
  ) => {
    const fieldName = 'termsAndConditionsUrl';
    let finalListingVersion = listingVersion ? listingVersion : 1;
    let filePath = `tnc/${orgId}/${id}/${finalListingVersion}/${termsFileName}`;
    return new Promise((resolve, reject) => {
      this.uploadDocUtils
        .uploadDoc(termsFile, filePath, termsType, signatureCoordinates)
        .then(docUrl => {
          resolve(docUrl);
        })
        .catch(err => {
          reject(err);
        });
    });
  };

  _changeStatus = async (
    options,
    status,
    commentText,
    emailSubject,
    emailType,
    solutionType
  ) => {
    const client = getApolloClient();
    let { formValueFinal, formStaticValues, solutionName, userData } = options;

    //formStaticValues, formValueFinal, userData, solutionType, status, commentText, emailSubject, emailType
    const comments = this._getStatusChangeComment(options, commentText, status);

    let listingVersion =
      formValueFinal && formValueFinal.listingVersion
        ? formValueFinal.listingVersion
        : '1';
    //change solution status
    const updateSolutionStatusResp = await client.mutate({
      mutation: updateSolutionStatusQuery,
      variables: {
        id: formValueFinal.id,
        listingVersion: listingVersion,
        status: status,
        lastUpdatedBy: userData.name,
        lastUpdatedOn: moment()
          .utc()
          .format(DATE_FORMAT),
        comments: comments,
        developerEmail: formStaticValues.developerId,
        orgId: formStaticValues.orgId,
        appName: formValueFinal.apigee_id,
        attributeName: 'solutionStatus',
        attributeValue: status,
        developerAgreement: formValueFinal.developerAgreement,
        publisherAgreementDetails: formValueFinal.publisherAgreementDetails
      }
    });

    // code here to update the app name in dnp solutions table
    if (
      updateSolutionStatusResp.data.updateSolutionStatus &&
      updateSolutionStatusResp.data.updateSolutionStatus.status &&
      updateSolutionStatusResp.data.updateSolutionStatus.status === 'Published'
    ) {
      await client.mutate({
        mutation: updatePlatformSolutionQuery,
        variables: {
          id: formValueFinal.id,
          listingVersion: listingVersion,
          developerEmail: formStaticValues.developerId,
          orgId: formStaticValues.orgId,
          appName: formValueFinal.overview.overview.name, //formValueFinal.apigee_id,
          status: status
        }
      });
    }

    //check for successful response before moving exec ahead
    if (
      updateSolutionStatusResp.data.updateSolutionStatus &&
      updateSolutionStatusResp.data.updateSolutionStatus.status &&
      updateSolutionStatusResp.data.updateSolutionStatus.status === status
    ) {
      //send email post status update
      status !== SolutionStatus.RECALLED
        ? await this._sendEmailForStatus(
            options,
            emailSubject,
            emailType,
            solutionName,
            status,
            formValueFinal.lastUpdatedOn
          )
        : Promise.resolve(true);

      //update state
      formValueFinal['status'] = status;
      formStaticValues['status'] = status;
      formStaticValues['comments']['comments'] = comments;
    }
  };

  _getStatusChangeComment = (options, commentText, status) => {
    let comment = this._createComment(options, commentText, status);
    let comments = JSON.parse(
      JSON.stringify(options.formStaticValues.comments.comments)
    );
    comments = comments ? comments : [];
    if (comment) {
      comments.push(comment);
    }
    return comments;
  };

  _getSubmissionComment = (options, status) => {
    let submissionNote = this._fetchSolutionNote(options.formValueFinal);
    let commentText =
      submissionNote === ''
        ? `Submitted for review by user ${options.formValueFinal.developerName}`
        : submissionNote;
    let comment = this._createComment(options, commentText, status);
    return { comment: comment, commentText: commentText };
  };

  _createComment = (options, commentText, status) => {
    return {
      userId: options.userData.name,
      commentDate: moment()
        .utc()
        .format(DATE_FORMAT),
      commentText: commentText,
      action: status
    };
  };

  _fetchSolutionNote = formValueFinal => {
    let submissionNote =
      formValueFinal &&
      formValueFinal.solution_notes &&
      formValueFinal.solution_notes.solution_notes &&
      formValueFinal.solution_notes.solution_notes.reviewersComments
        ? formValueFinal.solution_notes.solution_notes.reviewersComments
        : '';
    return submissionNote;
  };

  _mapStaticData = async (options, status, comment) => {
    const { formValueFinal, formStaticValues, userData } = options;
    let staticData = JSON.parse(JSON.stringify(formStaticValues));
    staticData.status = status;
    staticData.developerId = userData.email;
    staticData.developerName = userData.name;
    staticData.activateOnly = formValueFinal.activateOnly;
    staticData.submittedOn = moment()
      .utc()
      .format(DATE_FORMAT);
    staticData.lastUpdatedBy = userData.name;
    staticData.lastUpdatedOn = moment()
      .utc()
      .format(DATE_FORMAT);
    let comments = JSON.parse(JSON.stringify(staticData.comments.comments));
    comments = comments ? comments : [];
    if (comment) {
      comments.push(comment);
      staticData.comments.comments = comments;
      formValueFinal.comments.comments = comments;
    }
    return {
      formValueFinal: formValueFinal,
      staticData: staticData
    };
  };

  _mapFormValueForSubmit = async (formValues, staticData) => {
    let shouldValidate = false;
    return FormUtils.mapFormValuesForSubmit(
      formValues,
      staticData,
      FormConfig,
      JSON.parse(JSON.stringify(SolutionFormData)),
      shouldValidate
    );
  };

  _validateForm = async (valuesToSubmit, menu) => {
    let response = FormValidationUtils.validateForm(valuesToSubmit, FormConfig);
    let localMenu;
    if (!response.isValid && menu) {
      localMenu = menu;
      localMenu.activeMenuId = response.field.activeMenuId;
      localMenu.nestedActiveMenuId = response.field.nestedActiveMenuId;
    }
    return Promise.resolve(response);
  };

  _sendSubmissionEmailWithSignedDocument = async (
    options,
    emailSubject,
    emailType
  ) => {
    const { formStaticValues, formValueFinal, userData, entityInfo } = options;
    const client = getApolloClient();
    let userNameData = formStaticValues.developerName.split(' ');
    let fileName = formValueFinal?.overview?.overview?.name || 'app-agreement';
    let agreementUrl = formValueFinal?.developerAgreement || '';
    let attachment = null;
    attachment = this.getAttachment(fileName, agreementUrl);
    let apiDetails = [];
    let apiDetailsArrayForHTML = [];
    let apiData = [];
    if (
      Array.isArray(
        formValueFinal.registration_details.registration_details.api_details
      )
    ) {
      formValueFinal.registration_details.registration_details.api_details.forEach(
        apiItem => {
          apiData = [];
          if (
            apiItem.id !== environmentURLs.TestAPI &&
            Array.isArray(apiItem.providers)
          ) {
            apiItem.providers.forEach(providerItem => {
              let obj = {};
              if (providerItem.consent) {
                obj['apiName'] = apiItem.displayName;
                obj['providerName'] = providerItem.name;
                obj['entityId'] = providerItem.entityId;
                obj['productName'] = providerItem.productName;
                obj['consent'] = providerItem.consent.signedTermsAndConditions
                  ? 'Electronic Signature'
                  : 'One Click';
                apiData.push(obj);
              }
              else{
                obj['apiName'] =
                apiItem && apiItem.displayName ? apiItem.displayName : '';
                apiDetailsArrayForHTML.push(obj);
              }
            });
            apiDetails.push(apiData);
          } else {
            let obj = {};
            if (apiItem.id !== environmentURLs.TestAPI) {
              obj['apiName'] =
                apiItem && apiItem.displayName ? apiItem.displayName : '';
              apiDetailsArrayForHTML.push(obj);
            }
          }
        }
      );
    }

    let htmlStr = '';
    if (apiDetails.length > 0) {
      apiDetails.forEach(val => {
        val.forEach(item => {
          htmlStr += val.length > 1 || apiDetails.length > 1 ? `<ul><li>` : ``;
          htmlStr += `API: ${item.apiName}</br>Organization: ${
            item.providerName
          }</br>
         Implementation: ${item.productName}</br>Entity ID: ${
            item.entityId
          }</br>
         Consent-Type: ${item.consent}</br>`;
          htmlStr +=
            val.length > 1 || apiDetails.length > 1 ? `</li></ul>` : ``;
        });
      });
    }

    let contractNotificationsFlag =
      formValueFinal.contract_notifications &&
      formValueFinal.contract_notifications.contractNotificationsType
        ? formValueFinal.contract_notifications.contractNotificationsType
        : '';
    const [firstName, lastName] =
      userData.name && userData.name.length > 0 ? userData.name.split(' ') : '';
    await client.mutate({
      mutation: app_submission_contract,
      variables: {
        currentdate: moment()
          .utc()
          .format('DD MMM YYYY'),
        organizationName: entityInfo && entityInfo.name,
        entityId: entityInfo && entityInfo.id,
        cmf: 'N/A',
        firstName: firstName || '',
        lastName: lastName || '',
        userEmail: userData.email,
        solutionName: formValueFinal.overview.overview.name,
        solutionId: formValueFinal.id,
        consentType: 'Electronic Signature',
        apiList: htmlStr,
        emailSubject: emailSubject,
        emailType: emailType,
        attachments: attachment,
        apiDetails: apiDetailsArrayForHTML,
        contractNotificationsFlag: contractNotificationsFlag
      }
    });
  };

  sendStatusEmailWithPaymentDetails = (options, solutionStatus) => {
    const { formValueFinal, prevListing } = options;

    // send email with payment details for Publish - change in price
    // send email with payment details for Withdraw - if price
    const stripePriceId = formValueFinal.stripePriceId || '';
    const prevStripePriceId = prevListing?.stripePriceId || '';
    return (
      formValueFinal.activateOnly !== false &&
      ((solutionStatus === SolutionStatus.PUBLISHED &&
        stripePriceId !== prevStripePriceId) ||
        (solutionStatus === SolutionStatus.WITHDRAWN && stripePriceId))
    );
  };

  _sendEmailForStatus = async (
    options,
    emailSubject,
    emailType,
    solutionName,
    solutionStatus,
    updatedOn
  ) => {
    const {
      formStaticValues,
      formValueFinal,
      userData,
      paymentDetails = {},
      stripeSubscriptionData,
      entityInfo = {}
    } = options;
    const client = getApolloClient();
    const countryCode = entityInfo?.address?.countryCode;
    let loggedinUserData =
      userData && userData.name && userData.name.length > 0
        ? userData.name.split(' ')
        : [];
    const adminIds =
      (environmentURLs.fortellisAdmin &&
        environmentURLs.fortellisAdmin.split(',')) ||
      [];
    const opsEmails = adminIds.map(id => {
      return { email: id };
    });
    let attachment = null;
    const getInCurrency = (value, countryCode) => {
      const dollarValue =
        value &&
        parseFloat(value).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          currencyDisplay: 'symbol'
        });
      if (countryCode === 'CA') {
        return `${dollarValue} CAD`;
      }
      return dollarValue;
    };

    let prevListingFee = '';
    let listingFee = '';
    let nextPaymentDate = '';
    let fileName = formValueFinal?.overview?.overview?.name || 'app-agreement';
    let agreementUrl = formValueFinal?.developerAgreement || '';
    if (this.sendStatusEmailWithPaymentDetails(options, solutionStatus)) {
      if (solutionStatus === SolutionStatus.PUBLISHED) {
        attachment = this.getAttachment(fileName, agreementUrl);
        const paymentMethod = paymentDetails.paymentMethod;
        emailSubject = 'Your Fortellis App Has Been Published';
        if (
          paymentMethod === 'bankACH' &&
          paymentDetails.microDepositVerification
        ) {
          emailType = 'solutionPublishedISVPaidByACH';
        } else if (paymentMethod === 'card' || paymentMethod === 'bankACH') {
          emailType = 'solutionPublishedISVPaidByCC_ACH';
        } else if (stripeSubscriptionData && stripeSubscriptionData.length) {
          const taxRate = parseFloat(stripeSubscriptionData[0].tax_rate);
          emailType = 'solutionPublishedListingFeeChanged';
          prevListingFee = getInCurrency(
            parseFloat(stripeSubscriptionData[0].order_total_amount || '0'),
            countryCode
          );
          listingFee = getInCurrency(
            parseFloat(formValueFinal.appPublishingFee * (1 + taxRate)).toFixed(
              2
            ) || 0,
            countryCode
          );
          nextPaymentDate = stripeSubscriptionData[0].next_payment_date;
        }
      } else if (solutionStatus === SolutionStatus.WITHDRAWN) {
        emailSubject = 'Your Fortellis App is Withdrawn';
        emailType = 'solutionWithdrawnPaymentStopped';
      }
      await client.mutate({
        mutation: solution_status_email_with_payment_details,
        variables: {
          to: [{ email: formStaticValues.developerId }],
          cc: opsEmails,
          emailSubject: emailSubject,
          emailType: emailType,
          firstName: this.getUserName(loggedinUserData).firstName,
          lastName: this.getUserName(loggedinUserData).lastName,
          solutionName: solutionName,
          solutionId: formStaticValues.id,
          listingVersion: formValueFinal.listingVersion,
          paymentMethod: paymentDetails.paymentMethod,
          last4: paymentDetails.last4,
          invoiceURL: paymentDetails.invoiceURL,
          prevListingFee,
          listingFee,
          nextPaymentDate,
          attachments: attachment
        }
      });
    } else if (solutionStatus === SolutionStatus.PUBLISHED && formValueFinal.appPublishingFee === '0') {
      emailSubject = 'Your Fortellis App Has Been Published'
      emailType = 'solutionPublishedISVListingFeeWaivedOff'
      attachment = this.getAttachment(fileName, agreementUrl);
      await client.mutate({
        mutation: solution_status_email_without_payment_details,
        variables: {
          to: [{ email: formStaticValues.developerId }],
          cc: opsEmails,
          emailSubject: emailSubject,
          emailType: emailType,
          firstName: this.getUserName(loggedinUserData).firstName,
          lastName: this.getUserName(loggedinUserData).lastName,
          solutionName: solutionName,
          attachments: attachment,
          solutionId: formStaticValues.id,
        }
      });
    } else {
      await client.mutate({
        mutation: sent_status_email,
        variables: {
          emailAddress: formStaticValues.developerId,
          emailSubject: emailSubject,
          emailType: emailType,
          solutionName: solutionName,
          solutionReviewComments: ``,
          firstName: this.getUserName(loggedinUserData).firstName,
          lastName: this.getUserName(loggedinUserData).lastName,
          attachments: attachment
        }
      });
    }
    await adminIds.map(adminId => {
      return client.mutate({
        mutation: sent_status_email,
        variables: {
          emailAddress: adminId,
          emailSubject: 'Fortellis App Has Been ' + solutionStatus,
          emailType: 'solutionOperationsAdminEmail',
          solutionName: solutionName,
          solutionStatus: solutionStatus,
          solutionReviewComments: ``,
          firstName: this.getUserName(loggedinUserData).firstName,
          lastName: this.getUserName(loggedinUserData).lastName,
          updatedOn: updatedOn ? moment(updatedOn).format('DD MMM YYYY') : ''
        }
      });
    });
  };

  _saveFormData = async valuesToSubmit => {
    const client = getApolloClient();
    let values = JSON.parse(
      JSON.stringify(valuesToSubmit, (key, value) => {
        if (value === '') {
          return null;
        }
        return value;
      })
    );
    delete values['solution_notes'];

    //provider info removed from api_details since it gets added for UI use
    if (
      values.registration_details &&
      values.registration_details.api_details &&
      values.registration_details.api_details.length
    ) {
      values.registration_details.api_details.forEach(api => {
        if (api.apiType !== 'async-api' && api.providers) {
          delete api.providers;
        }
      });
    }

    const response = await client.mutate({
      mutation: query,
      variables: {
        formFields: values
      }
    });

    return Promise.resolve(response);
  };
  notifyApiProviderActivation = async emailConfig => {
    const client = getApolloClient();
    try {
      const response = await client.mutate({
        mutation: send_api_activation_email,
        variables: {
          to: emailConfig.to,
          subject: emailConfig.subject,
          attachments: emailConfig.attachments,
          bcc: emailConfig.bcc,
          cc: emailConfig.cc,
          withTemplate: emailConfig.withTemplate,
          userDetails: emailConfig.userDetails
        }
      });
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  notifyApiProviderActivation = async emailConfig => {
    const client = getApolloClient();
    try {
      const response = await client.mutate({
        mutation: send_api_activation_email,
        variables: {
          to: emailConfig.to,
          subject: emailConfig.subject,
          attachments: emailConfig.attachments,
          bcc: emailConfig.bcc,
          cc: emailConfig.cc,
          withTemplate: emailConfig.withTemplate,
          userDetails: emailConfig.userDetails
        }
      });
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  notifyInternalAlertOfApiAddition = async emailConfig => {
    const client = getApolloClient();
    try {
      const response = await client.mutate({
        mutation: send_internal_alert_of_api_addition_email_,
        variables: {
          to: emailConfig.to,
          subject: emailConfig.subject,
          attachments: emailConfig.attachments,
          withTemplate: emailConfig.withTemplate,
          userDetails: emailConfig.userDetails
        }
      });
      return response;
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  notifyAPIProvider = async (
    solution,
    entity,
    userData,
    contractNotificationsFlag = ''
  ) => {
    const client = getApolloClient();
    const response = await Promise.all([
      client.mutate({
        mutation: notifyApiProviderMutation,
        variables: {
          id: solution.id,
          solutionName: solution.overview.name,
          companyName: solution.overview.publisherName,
          solutionSupportEmail: solution.support.emailAddress,
          buyerName: userData.name,
          recipentType: 'provider',
          contractNotificationsFlag: ''
        }
      }),
      client.mutate({
        mutation: notifyApiProviderMutation,
        variables: {
          id: solution.id,
          solutionName: solution.overview.name,
          companyName: solution.overview.publisherName,
          solutionSupportEmail: solution.support.emailAddress,
          buyerName: userData.name,
          recipentType: 'internal',
          contractNotificationsFlag: contractNotificationsFlag
        }
      })
    ]);

    return response;
  };

  sendEmailOnPublishPending = async options => {
    const { formStaticValues, solutionName, paymentDetails } = options;
    const adminIds =
      (environmentURLs.fortellisAdmin &&
        environmentURLs.fortellisAdmin.split(',')) ||
      [];
    const opsEmails = adminIds.map(id => {
      return { email: id };
    });
    let userNameData = formStaticValues.developerName.split(' ');
    const client = getApolloClient();
    const emailSubject = `Payment for the Listing fee towards your app (${solutionName}) has been initiated`;
    const emailType = 'listingFeePaymentInitiatedUsingACH';
    try {
      await client.mutate({
        mutation: solution_status_email_with_payment_details,
        variables: {
          to: [{ email: formStaticValues.developerId }],
          cc: opsEmails,
          emailSubject,
          emailType,
          firstName: this.getUserName(userNameData).firstName,
          lastName: this.getUserName(userNameData).lastName,
          solutionName: solutionName,
          solutionId: formStaticValues.id,
          last4: paymentDetails.last4
        }
      });
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  onPublishPending = async options => {
    const {
      formValueFinal,
      solutionWillPublishPending,
      solutionWasPublishPending
    } = options;

    let status = SolutionStatus.PUBLISH_PENDING;

    if (
      solutionWillPublishPending &&
      solutionWillPublishPending instanceof Function
    ) {
      solutionWillPublishPending();
    }

    let submissionNote = await this._fetchSolutionNote(formValueFinal);
    let commentText =
      submissionNote === ''
        ? `Publishe pending by user ${formValueFinal.developerName}`
        : submissionNote;

    try {
      await this._changeStatus(
        options,
        status,
        commentText,
        `Your Fortellis App Has Been Published`,
        `solutionPublishedEmail`,
        formValueFinal.solutionType
      );

      if (
        solutionWasPublishPending &&
        solutionWasPublishPending instanceof Function
      ) {
        solutionWasPublishPending(formValueFinal);
      }

      await this.sendEmailOnPublishPending(options);
      // try {
      //   await this._sendSubmissionEmailWithSignedDocument(
      //     options,
      //     "Internal Alert - App Publisher Signed Fortellis Agreement",
      //     "solutionSubmissionAppContract"
      //   );
      // } catch (e) {
      //   console.error(e);
      // }
    } catch (err) {
      throw err;
    }
  };
}

const appSubmissionFragment = gql`
  fragment SolutionOperationModel on Solution {
    id
    apigee_id
    developerId
    orgId
  }
`;

export const query = gql`
  mutation($formFields: SubmitSolutionCmd) {
    submitSolution(command: $formFields) {
      ...SolutionOperationModel
    }
  }
  ${appSubmissionFragment}
`;

export const sent_status_email = gql`
  mutation(
    $emailAddress: String!
    $emailSubject: String!
    $emailType: String!
    $solutionName: String
    $solutionReviewComments: String
    $firstName: String!
    $lastName: String
    $solutionStatus: String
    $updatedOn: String
  ) {
    sendEmail(
      command: {
        emailAddress: $emailAddress
        emailSubject: $emailSubject
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionReviewComments: $solutionReviewComments
          solutionStatus: $solutionStatus
          updatedOn: $updatedOn
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const send_api_activation_email = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $subject: String!
    $attachments: [csmsgEmailAttachmentInput]
    $bcc: [csmsgEmailInput]
    $cc: [csmsgEmailInput]
    $withTemplate: withTemplateCmd!
    $userDetails: userDetailsCmd
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $subject
        attachments: $attachments
        bcc: $bcc
        cc: $cc
        withTemplate: $withTemplate
        userDetails: $userDetails
      }
    ) {
      emailResponse
    }
  }
`;

export const send_internal_alert_of_api_addition_email_ = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $subject: String!
    $attachments: [csmsgEmailAttachmentInput]
    $withTemplate: withTemplateCmd!
    $userDetails: userDetailsCmd
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $subject
        attachments: $attachments
        withTemplate: $withTemplate
        userDetails: $userDetails
      }
    ) {
      emailResponse
    }
  }
`;

export const app_submission_contract = gql`
  mutation(
    $currentdate: String
    $organizationName: String
    $entityId: String
    $cmf: String
    $firstName: String!
    $lastName: String
    $userEmail: String
    $solutionName: String
    $solutionId: String
    $consentType: String
    $apiList: String
    $apiDetails: [apiDetailCmd]
    $emailSubject: String!
    $emailType: String!
    $attachments: [csmsgEmailAttachmentInput]
    $contractNotificationsFlag: String
  ) {
    sendEmailCSMG(
      command: {
        subject: $emailSubject
        attachments: $attachments
        withTemplate: {
          emailType: $emailType
          currentdate: $currentdate
          organizationName: $organizationName
          entityId: $entityId
          cmf: $cmf
          userEmail: $userEmail
          solutionName: $solutionName
          solutionId: $solutionId
          consentType: $consentType
          apiList: $apiList
          apiDetails: $apiDetails
          contractNotificationsFlag: $contractNotificationsFlag
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const updateSolutionStatusQuery = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $status: String
    $lastUpdatedBy: String
    $lastUpdatedOn: String
    $comments: [CommentCmd]
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $attributeName: String!
    $attributeValue: String!
    $developerAgreement: String
    $publisherAgreementDetails: publisherAgreementDetailsCmd
  ) {
    updateSolutionStatus(
      command: {
        id: $id
        listingVersion: $listingVersion
        status: $status
        lastUpdatedBy: $lastUpdatedBy
        lastUpdatedOn: $lastUpdatedOn
        comments: $comments
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        attributeName: $attributeName
        attributeValue: $attributeValue
        developerAgreement: $developerAgreement
        publisherAgreementDetails: $publisherAgreementDetails
      }
    ) {
      id
      listingVersion
      status
      error {
        message
      }
    }
  }
`;

const notifyApiProviderMutation = gql`
  mutation(
    $id: String
    $solutionName: String
    $companyName: String
    $solutionSupportEmail: String
    $buyerName: String
    $recipentType: String
    $contractNotificationsFlag: String
  ) {
    notifyApiProviders(
      command: {
        id: $id
        solutionName: $solutionName
        companyName: $companyName
        solutionSupportEmail: $solutionSupportEmail
        buyerName: $buyerName
        recipentType: $recipentType
        contractNotificationsFlag: $contractNotificationsFlag
      }
    ) {
      id
    }
  }
`;

const notifyAPIProviderGroupAPi = gql`
  mutation(
    $to: [csmsgEmailInput]
    $emailSubject: String!
    $subscriberOrganization: String
    $emailType: String!
    $entityId: String
    $subscriberName: String
    $subscriptionId: String
    $buyerEmail: String
    $appName: String
    $appOrgName: String
    $appOrgId: String
    $appTermsConsentType: String
    $buyerName: String
    $apis: [apiDetailCmd]
    $attachments: [csmsgEmailAttachmentInput]
    $firstName: String!
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        subject: $emailSubject
        userDetails: { firstName: $firstName }
        withTemplate: {
          emailType: $emailType
          appDetails: {
            appName: $appName
            appOrgName: $appOrgName
            appOrgId: $appOrgId
            appTermsConsentType: $appTermsConsentType
          }
          apiDetails: $apis
          subscriptionDetails: {
            buyerName: $buyerName
            subscriberOrganization: $subscriberOrganization
            entityId: $entityId
            subscriberName: $subscriberName
            buyerEmail: $buyerEmail
            subscriptionId: $subscriptionId
          }
        }
        attachments: $attachments
      }
    ) {
      emailResponse
    }
  }
`;

export const solution_status_email_with_payment_details = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $cc: [csmsgEmailInput]!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $lastName: String
    $solutionName: String
    $listingVersion: String
    $solutionId: String
    $paymentMethod: String
    $last4: String
    $invoiceURL: String
    $prevListingFee: String
    $listingFee: String
    $nextPaymentDate: String
    $attachments: [csmsgEmailAttachmentInput]
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        cc: $cc
        subject: $emailSubject
        attachments: $attachments
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          listingVersion: $listingVersion
          solutionId: $solutionId
          paymentDetails: {
            paymentMethod: $paymentMethod
            last4: $last4
            invoiceURL: $invoiceURL
            prevListingFee: $prevListingFee
            listingFee: $listingFee
            nextPaymentDate: $nextPaymentDate
          }
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const solution_status_email_without_payment_details = gql`
  mutation(
    $to: [csmsgEmailInput]!
    $cc: [csmsgEmailInput]!
    $emailSubject: String!
    $emailType: String!
    $firstName: String!
    $lastName: String
    $solutionName: String
    $solutionId: String
    $attachments: [csmsgEmailAttachmentInput]
  ) {
    sendEmailCSMG(
      command: {
        to: $to
        cc: $cc
        subject: $emailSubject
        attachments: $attachments
        withTemplate: {
          emailType: $emailType
          solutionName: $solutionName
          solutionId: $solutionId
        }
        userDetails: { firstName: $firstName, lastName: $lastName }
      }
    ) {
      emailResponse
    }
  }
`;

export const updatePlatformSolutionQuery = gql`
  mutation(
    $id: String!
    $listingVersion: String!
    $developerEmail: String!
    $orgId: String!
    $appName: String!
    $status: String!
  ) {
    updatePlatformSolution(
      command: {
        id: $id
        listingVersion: $listingVersion
        developerEmail: $developerEmail
        orgId: $orgId
        appName: $appName
        status: $status
      }
    ) {
      id
      listingVersion
      status
      error {
        message
      }
    }
  }
`;

const DATE_FORMAT = 'DD MMM YYYY hh:mm A';

export default SolutionOperations;
