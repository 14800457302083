import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApolloClient } from '../../../containers/GraphQLClient';
import gql from 'graphql-tag';
import axios from 'axios';
import { environmentURLs } from '../../../components/common/environment/CaptureEnvironment';
import { SEARCH_TYPE } from '../../../components/admin-account/subscriptions-data/constants';

export const initialState = {
  searchResultsByApp: [],
  searchResultsByOrg: [],
  fetchingSearchResultsByApp: false,
  fetchingSearchResultsByOrg: false,
  noResultsFoundByApp: false,
  noResultsFoundByOrg: false,
  subscriptionDetails: [],
  fetchingSubscriptionDetails: false,
  errorSubscriptionDetails: false
};

const ENDPOINTS = {
  SUBSCRIPTION: '/v1/admin/subscriptions'
};

const formatAddress = addressObj => {
  if (!addressObj || typeof addressObj !== 'object') return '';

  const { street, city, region, countryCode, postalCode } = addressObj;
  return [street, city, region, countryCode, postalCode]
    .filter(Boolean)
    .join(', ');
};

const normalizeArrayField = field => {
  if (!Array.isArray(field)) return field;
  return field[0] ? field.join(' ') : null;
};

const processMetadata = metadata => {
  if (!metadata) return null;

  return {
    ...metadata,
    selectedCmfNumber: normalizeArrayField(metadata.selectedCmfNumber)
  };
};

const processSubscriptionData = subscription => {
  if (!subscription) return null;

  const processed = {
    ...subscription,
    storeId: normalizeArrayField(subscription.storeId),
    orgAddress: formatAddress(subscription.orgAddress),
    created:
      subscription.created && !subscription.created.endsWith('Z')
        ? `${subscription.created}Z`
        : subscription.created
  };

  return processed;
};

const extractApiDetails = solution => {
  if (!solution) return [];
  let { asyncIntegrations, restIntegrations } = solution;
  asyncIntegrations = Array.isArray(asyncIntegrations) ? asyncIntegrations : [];
  restIntegrations = Array.isArray(restIntegrations) ? restIntegrations : [];

  const processIntegration = (integration, isAsync) => ({
    id: isAsync ? integration.asyncApiId : integration.apiId,
    displayName: isAsync ? integration.asyncApiName : integration.apiName,
    privacyProtection: integration.privacyProtection || null,
    fields: []
  });

  const apiDetails = [
    ...asyncIntegrations.map(api => processIntegration(api, true)),
    ...restIntegrations.map(api => processIntegration(api, false))
  ];

  return apiDetails.map(api => ({
    ...api,
    // INFO: Currently will have field to be null
    // fields: api.fields ? [...new Set(api.fields)] : null
    fields: null
  }));
};

const transformSolutionData = solution => {
  if (!solution) return null;

  const apiDetails = extractApiDetails(solution);

  return {
    id: solution.id,
    name: solution.appName,
    status: solution.status,
    apiProducts: null,
    overview: solution.overview,
    orgId: solution.orgId,
    orgName: solution.orgName,
    activateOnly: solution.activateOnly,
    registration_details: {
      api_details: apiDetails
    },
    data_rights: solution.dataRights
  };
};

export const getSubscriptionDataForAdmin = createAsyncThunk(
  'subscription/getAdminData',
  async ({ searchValue, searchType, token }) => {
    const response = await axios.get(
      `${environmentURLs.subscriptionServiceUrl}${ENDPOINTS.SUBSCRIPTION}`,
      {
        params: {
          searchBy: searchType,
          searchWith: searchValue,
          expand: true
        },
        headers: { Authorization: `Bearer ${token}` }
      }
    );

    return { ...response.data, searchType };
  }
);

const processSubscriptionsBySearchType = ({
  subscriptionData,
  solutionData,
  searchType,
  metadataSubscriptions
}) => {
  if (!Object.values(SEARCH_TYPE).includes(searchType)) {
    return [];
  }

  if (searchType === SEARCH_TYPE.CMF) {
    return processCmfSearchResults({
      solutionData,
      metadataSubscriptions
    });
  }

  return processNonCmfSearchResults({
    subscriptionData,
    solutionData,
    searchType,
    metadataSubscriptions
  });
};

const processCmfSearchResults = ({ solutionData, metadataSubscriptions }) => {
  if (!metadataSubscriptions) return [];
  return metadataSubscriptions.map(metadata => {
    const {
      subscriptionId,
      solutionId,
      orgId,
      created,
      status,
      orgAddress,
      orgName,
      storeId
    } = metadata;

    const solution = solutionData?.find(sol => sol.id === solutionId);

    const subscription = {
      subscriptionId,
      status,
      orgId,
      created,
      orgName,
      orgAddress,
      solutionId,
      storeId
    };

    return {
      subscriptionData: processSubscriptionData(subscription),
      solution: transformSolutionData(solution),
      metadata: processMetadata(metadata)
    };
  });
};

const processNonCmfSearchResults = ({
  subscriptionData,
  solutionData,
  searchType,
  metadataSubscriptions
}) => {
  if (!subscriptionData) return [];

  return subscriptionData.map(subscription => {
    const { solutionId, orgId } = subscription;
    let preferredMetadata = [];

    if (metadataSubscriptions) {
      const matchingMetadata =
        metadataSubscriptions?.filter(meta =>
          searchType === SEARCH_TYPE.APP || searchType === SEARCH_TYPE.SUB
            ? meta.orgId === orgId
            : meta.solutionId === solutionId
        ) || [];

      preferredMetadata = matchingMetadata.sort((a, b) => {
        const countNulls = obj =>
          Object.values(obj).filter(value => value === null).length;
        return countNulls(a) - countNulls(b);
      })[0];
    }

    const solution = solutionData?.find(sol => sol.id === solutionId);

    return {
      subscriptionData: processSubscriptionData(subscription),
      solution: transformSolutionData(solution),
      metadata: processMetadata(preferredMetadata)
    };
  });
};

export const getSearchSuggestionsByApp = createAsyncThunk(
  'getSearchSuggestionsByApp',
  async ({ appName }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query(
          $from: Int
          $sortBy: String
          $order: String
          $searchText: String
          $searchOnly: Boolean
          $needAllSolutionVisibilityTypes: Boolean
          $isAdmin: Boolean
        ) {
          searchStoreFrontSolution(
            filters: {
              from: $from
              sortBy: $sortBy
              order: $order
              searchText: $searchText
              searchOnly: $searchOnly
              needAllSolutionVisibilityTypes: $needAllSolutionVisibilityTypes
              isAdmin: $isAdmin
            }
          ) {
            page
            pageSize
            total
            data {
              id
              name
            }
          }
        }
      `,
      variables: {
        searchText: appName,
        searchOnly: true,
        needAllSolutionVisibilityTypes: true,
        isAdmin: true
      }
    });

    return responseObj &&
      responseObj.data &&
      responseObj.data.searchStoreFrontSolution
      ? responseObj.data.searchStoreFrontSolution
      : null;
  }
);

export const getSearchSuggestionsByOrg = createAsyncThunk(
  'getSearchSuggestionsByOrg',
  async ({ orgName }) => {
    let responseObj = await getApolloClient().query({
      query: gql`
        query($orgName: String) {
          getOrganizations(orgName: $orgName) {
            id
            name
          }
        }
      `,
      variables: {
        orgName: orgName
      }
    });
    return responseObj && responseObj.data && responseObj.data.getOrganizations;
  }
);

const subscriptionDataSlice = createSlice({
  name: 'subscriptionData',
  initialState: initialState,
  reducers: {
    clearSubscriptionData: state => {
      state.searchResultsByApp = [];
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByApp = false;
      state.fetchingSearchResultsByOrg = false;
      state.noResultsFoundByApp = false;
      state.noResultsFoundByOrg = false;

      state.subscriptionDetails = [];
      state.fetchingSubscriptionDetails = false;
      state.errorSubscriptionDetails = false;
    }
  },
  extraReducers: {
    [getSearchSuggestionsByApp.fulfilled]: (state, action) => {
      const results = action.payload.data;
      const searchResults = [];
      results &&
        results.length &&
        results.map(item => {
          searchResults.push({ id: item.id, name: item.name });
        });
      state.searchResultsByApp = searchResults;
      state.fetchingSearchResultsByApp = false;
      if (!searchResults.length) {
        state.noResultsFoundByApp = true;
      }
    },
    [getSearchSuggestionsByApp.pending]: state => {
      state.searchResultsByApp = [];
      state.fetchingSearchResultsByApp = true;
      state.noResultsFoundByApp = false;
    },
    [getSearchSuggestionsByApp.rejected]: state => {
      state.searchResultsByApp = [];
      state.fetchingSearchResultsByApp = false;
      state.noResultsFoundByApp = false;
    },

    [getSearchSuggestionsByOrg.fulfilled]: (state, action) => {
      const results = action.payload;
      state.searchResultsByOrg = results;
      state.fetchingSearchResultsByOrg = false;
      if (!results || !results.length) {
        state.noResultsFoundByOrg = true;
      }
    },
    [getSearchSuggestionsByOrg.pending]: state => {
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByOrg = true;
      state.noResultsFoundByOrg = false;
    },
    [getSearchSuggestionsByOrg.rejected]: state => {
      state.searchResultsByOrg = [];
      state.fetchingSearchResultsByOrg = false;
      state.noResultsFoundByOrg = false;
    },

    [getSubscriptionDataForAdmin.fulfilled]: (state, action) => {
      state.subscriptionDetails = processSubscriptionsBySearchType(
        action.payload
      );
      state.fetchingSubscriptionDetails = false;
      state.errorSubscriptionDetails = false;
    },
    [getSubscriptionDataForAdmin.pending]: state => {
      state.subscriptionDetails = [];
      state.fetchingSubscriptionDetails = true;
      state.errorSubscriptionDetails = false;
    },
    [getSubscriptionDataForAdmin.rejected]: state => {
      state.subscriptionDetails = [];
      state.fetchingSubscriptionDetails = false;
      state.errorSubscriptionDetails = true;
    }
  }
});

export const { clearSubscriptionData } = subscriptionDataSlice.actions;

export default subscriptionDataSlice.reducer;
